.root {
  background-color: var(--beige-color);
  background-image: url('assets/backgroundBottomLeft.svg'),
    url('assets/backgroundTopRight.svg');
  background-size:
    auto 25vh,
    auto 20vh;
  background-position:
    bottom left,
    right top;
}
