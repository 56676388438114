.root {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.pageWrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity 1s ease-in-out;

  &.activePage {
    opacity: 1;
  }
}

.route {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

button.button {
  position: fixed;
  z-index: 5000;
  bottom: 1rem;
  left: 1rem;
}
